



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Contact } from '@tada-team/tdproto-ts'
import { teamsStore } from '@/store'

@Component({
  components: {
    BaseAvatar: () => import('@/components/UI/BaseAvatar.vue'),
  },
})
export default class MetaMember extends Vue {
  @Prop({
    required: true,
    type: Contact,
  }) contact!: Contact

  private openChat () {
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.contact.jid,
      },
    })
  }
}
